import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Parser } from "html-to-react";
import { Triangle } from "react-loader-spinner";

const Pages = () => {
  const { slug } = useParams();

  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-pages/${slug}`
        );
        const data = await res.json();

        setPageData(data);
        setLoading(false);
      } catch (error) {
        setLoading(true);
        console.log(error.message);
      }
    };
    fetchData();
  }, [slug]);

  const htmlParser = new Parser();

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center min-h-[calc(100vh-160px)]">
          <Triangle
            visible={true}
            height="120"
            width="120"
            color="#000"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="container mx-auto min-h-[calc(100vh-160px)] bg-[#F2F3F8] px-4 py-10">
          <h2 className="text-2xl font-semibold text-center">
            {pageData.title}
          </h2>
          <p className="mt-10 ">{htmlParser.parse(pageData.descriptions)}</p>
        </div>
      )}
    </div>
  );
};

export default Pages;

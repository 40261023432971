import { RiUser3Line } from "react-icons/ri";
import { CiGrid41 } from "react-icons/ci";

export const vendormenu = [
  {
    id: "1",
    icon: <RiUser3Line />,
    name: "Dashboard",
    path: `/user/vendordashboard`,
  },
  {
    id: "2",
    icon: <RiUser3Line />,
    name: "My Profile",
    path: `/user/profile`,
  },
  {
    id: "3",
    icon: <CiGrid41 />,
    name: "My Order",
    path: `/user/order`,
  },
  {
    id: "4",
    icon: <CiGrid41 />,
    name: "Vendor Order",
    path: `/user/vendororder`,
  },
  {
    id: "4",
    icon: <CiGrid41 />,
    name: "Payout",
    path: `/user/payout`,
  },
];

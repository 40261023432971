import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const vendorProductFetching = createAsyncThunk(
  "vendorProduct/vendorProductFetching",
  async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api/vendor/product?user_id=${id}`
    );
    return res.data;
  }
);

export const vendorProductSlice = createSlice({
  name: "vendorProduct",
  initialState,
  reducers: {
    removeProduct: (state, action) => {
      state.items.vendor_product = state.items.vendor_product.filter(
        (product) => product.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(vendorProductFetching.pending, (state) => {
      state.status = "loading...";
    });
    builder.addCase(vendorProductFetching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(vendorProductFetching.rejected, (state) => {
      state.status = "Something Went Wrong";
    });
  },
});

export const { removeProduct } = vendorProductSlice.actions;
export default vendorProductSlice.reducer;

import { useEffect, useState } from "react";
import { FaPlusCircle, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  removeProduct,
  vendorProductFetching,
} from "../../features/products/vendorProductSlice";
import moment from "moment";
import axios from "axios";

const VendorDashboard = () => {
  const dispatch = useDispatch();
  const { items: vendorProduct } = useSelector((state) => state.vendorProduct);
  const { userAndToken: vendorData } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  useEffect(() => {
    dispatch(vendorProductFetching(vendorData?.user?.id));
  }, [dispatch, vendorData?.user?.id]);

  const handleDeleteProduct = async (id) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/api/product-delete/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${vendorData?.access_token}`,
          },
        }
      );
      dispatch(removeProduct(id));
      alert("Product deleted successfully!");
    } catch (error) {
      console.error("There was an error updating the product:", error);
      alert("Failed to delete the product. Please try again.");
    }
  };
  const totalPages = Math.ceil(
    vendorProduct?.vendor_product?.length / itemsPerPage
  );

  // const vendorDashBoardMenu = [
  //   {
  //     id: "1",
  //     name: "Total Products",
  //     quantity: `${vendorData?.vendor_product?.length}`,
  //   },
  //   {
  //     id: "2",
  //     name: "Total Orders",
  //     quantity: `${vendorData?.vendor_order?.length}`,
  //   },
  // ];

  return (
    <div className="flex flex-col gap-5 px-5 py-5">
      <div className="flex justify-center ">
        <div className="w-60 h-28 bg-white rounded-md hover:shadow-md duration-300">
          <Link
            className="flex flex-col gap-3 justify-center items-center h-full"
            to={"/user/vendorproduct/upload"}
          >
            <FaPlusCircle className="text-4xl text-gray-700" />
            <span className="text-xl capitalize font-semibold text-[#C0392B]">
              Add New Products
            </span>
          </Link>
        </div>
      </div>
      {/* <div className="vendorDashboardMenu flex justify-center flex-wrap gap-4">
        {vendorDashBoardMenu?.map((item) => (
          <div key={item.id}>
            <VendorDashboardMenuCard item={item} />
          </div>
        ))}
      </div> */}
      <div className="flex flex-col gap-5">
        <h3 className="text-2xl font-semibold text-[#C0392B]">Products</h3>
        {vendorProduct?.vendor_product?.length > 0 && (
          <>
            <table className="w-full">
              <thead className="bg-gray-50 border-b-2 border-gray-200">
                <tr>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left"></th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Name
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Price
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Created At
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-right"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {vendorProduct?.vendor_product?.map((product) => (
                  <tr key={product.id} className="bg-gray-50">
                    <td className="p-3 w-20 text-sm text-gray-700">
                      <div className="image w-[60px] aspect-square h-[50px] overflow-hidden">
                        <img
                          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                          className="h-full w-full object-cover"
                          alt={product.name}
                        />
                      </div>
                    </td>
                    <td className="p-3 w-24 text-sm text-gray-700">
                      {product?.name}
                    </td>
                    <td className="p-3 w-20 text-sm text-gray-700">
                      ৳{product.unit_price}
                    </td>
                    <td className="p-3 w-4 text-sm text-gray-700">
                      {moment(product?.created_at).format("DD MMM YY. hh:mm A")}
                    </td>
                    <td className="p-2 w-2 text-sm text-gray-700 text-right">
                      <div className="flex text-2xl justify-end items-center gap-2">
                        <Link to={`/user/vendoredit/${product?.id}`}>
                          <FaRegEdit className="cursor-pointer hover:text-green-800 duration-300" />
                        </Link>
                        <FaTrash
                          onClick={() => handleDeleteProduct(product?.id)}
                          className="cursor-pointer hover:text-rose-500 duration-300"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-4 py-2 mx-1 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
              >
                Previous
              </button>
              {[...Array(totalPages).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => setCurrentPage(number + 1)}
                  className={`px-4 py-2 mx-1 ${
                    currentPage === number + 1
                      ? "bg-[#C0392B] text-white"
                      : "bg-gray-300"
                  } rounded hover:bg-gray-400`}
                >
                  {number + 1}
                </button>
              ))}
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-4 py-2 mx-1 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </>
        )}
        {vendorProduct?.vendor_product?.length === 0 && (
          <p className="text-center">No Product Found</p>
        )}
      </div>
    </div>
  );
};

export default VendorDashboard;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const vendorOrderFetching = createAsyncThunk(
  "vendorOrder/vendorOrderFetching",
  async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api/vendor/order?user_id=${id}`
    );
    return res.data;
  }
);

export const vendorOrderSlice = createSlice({
  name: "vendorOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(vendorOrderFetching.pending, (state) => {
      state.status = "loading...";
    });
    builder.addCase(vendorOrderFetching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(vendorOrderFetching.rejected, (state) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default vendorOrderSlice.reducer;

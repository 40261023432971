import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: null,

  loader: true,
};

//data fetching

export const categoryWiseSubCategoryFetching = createAsyncThunk(
  "categoryWiseSubCategory/categoryWiseSubCategoryFetching",
  async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/subcategorycollection/${id}`
    );
    return res.data;
  }
);

export const categoryWiseSubCategorySlice = createSlice({
  name: "categoryWiseSubCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      categoryWiseSubCategoryFetching.pending,
      (state, action) => {
        state.loader = true;
      }
    );
    builder.addCase(
      categoryWiseSubCategoryFetching.fulfilled,
      (state, action) => {
        state.loader = false;
        state.items = action.payload;
      }
    );
    builder.addCase(
      categoryWiseSubCategoryFetching.rejected,
      (state, action) => {
        state.status = "Something Went Wrong";
      }
    );
  },
});

export default categoryWiseSubCategorySlice.reducer;

import { useEffect } from "react";

import { useParams } from "react-router-dom";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { singleCategoryFetching } from "../features/category/singleCategorySlice";
import { Triangle } from "react-loader-spinner";

const CategoryPage = () => {
  const dispatch = useDispatch();

  const { items: data, loading } = useSelector(
    (state) => state.categoryProduct
  );

  // const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    dispatch(singleCategoryFetching(id));
    // setLoading(false);
    // dispatch(thumbImageFetching(id));
  }, [dispatch, id]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(`${process.env.REACT_APP_CATEGORY_URL}/${id}`);
  //       const data = await res.json();
  //       setCategoryProduct(data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchData();
  // }, [id]);
  return (
    <>
      {loading && (
        <div className="flex items-center justify-center min-h-[calc(100vh-25px)] ">
          <Triangle
            visible={true}
            height="120"
            width="120"
            color="#EC4C47"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {data?.length > 0 && (
        <div className="px-3 container mx-auto min-h-screen mb-10">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 mt-10 gap-2 md:gap-5">
            {data?.map((product) => (
              <div key={product.id} className="overflow-hidden">
                <Card product={product} />
              </div>
            ))}

            {/* {data.length === 0 && (
            <p className="text-center col-span-full">No Products Found</p>
          )} */}
          </div>
        </div>
      )}

      {data?.length === 0 && (
        <p className="text-center mt-10">No Product Found</p>
      )}
    </>
  );
};

export default CategoryPage;
